import React, { useEffect } from "react";

// Css Styles
import "./StartBox.css";

// Img, Icons
import { VideoIMg } from "../../../assets/Img";

const StartBox = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<div className='start-box'>
				<div className='all'>
					<div className='container'>
						<div className='start-content'>
							{/* How to Start body Content */}
							<div className='start-body'>
								<div className='info'>
									<h1>ليش تختار اطلبها</h1>
								</div>

								<p>
									منصة اطلبها منصتك التجارية تتميز بتقديم الاستشارات ودراسة
									الجدوى والدورات التدريبية وانشاء المتجر والسوق لعرض منتجاتك
									والتسويق عبر مشاهير السوشيال ميديا
								</p>
								<div className='box-video'>
									<div className='box-img'>
										<a
											href='http://www.youtube.com/channel/UCPypPqJuGFAGHz33RI7c5wg'
											target='blank'>
											<img
												width='100%'
												height='100%'
												src={VideoIMg}
												alt='ليش تختار اطلبها'
												loading='lazy'
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StartBox;
