import React from "react";
import { BlogBox } from "../components";
import { Helmet } from "react-helmet-async";

const Blog = () => {
	return (
		<>
			<Helmet>
				<title>أطلبها | المقالات</title>
				<meta
					name='description'
					content='مركز المعرفة الذي يجمع بين النصائح العملية والتحليلات العميقة لتعزيز استراتيجياتك في التجارة الإلكترونية. استكشف مجموعة غنية من المقالات المصممة لإلهامك وتوجيهك نحو اتخاذ قرارات مستنيرة وتحقيق النجاح في رحلتك التجارية.'
				/>
			</Helmet>
			<BlogBox />
		</>
	);
};

export default Blog;
