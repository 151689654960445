import React, { useContext, useEffect, useState } from "react";
import "./BlogHero.css";

// Icons
import { SvgSearch } from "../../assets/Icons";

// RTK Query
import { useSearchInBlogsMutation } from "../../RTK/Api/blogsApi";

// Context tp pass data from blog box to blog hero
import AppContext from "../../Context/AppContext";

const BlogHero = ({ setPosts, allBlogs, setSearchIsLoading }) => {
	const [search, setSearch] = useState("");

	// posts context
	const postsContext = useContext(AppContext);
	const { postCategoryId } = postsContext;

	// handle search in blogs
	const [searchInBlogs, { isLoading }] = useSearchInBlogsMutation();

	useEffect(() => {
		if (setSearchIsLoading) {
			setSearchIsLoading(isLoading);
		}
	}, [isLoading]);

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search !== "") {
				const fetchData = async () => {
					try {
						const response = await searchInBlogs({
							query: search,
							postCategoryId,
						});

						setPosts(response?.data?.data);
					} catch (error) {
						console.error("Error fetching searchInBlogs:", error);
					}
				};

				fetchData();
			} else {
				setPosts(allBlogs);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search, postCategoryId]);

	return (
		<>
			<div className='blog-hero'>
				<div className='container'>
					<div className='all'>
						<div className='form'>
							<input
								type='text'
								value={search}
								onChange={(e) => {
									setSearch(e.target.value);
								}}
								placeholder='البحث في المقالات 
								'
							/>
							<button>
								<SvgSearch />
							</button>
						</div>
						<h4>المقالات</h4>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlogHero;
