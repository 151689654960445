import React, { useState } from "react";

import { Pagination, Skeleton, Select, MenuItem } from "@mui/material";

// Icon
import { IoIosArrowDown } from "react-icons/io";

// RTK Query
import { useGetAllProductsQuery } from "../../RTK/Api/souqAtlbhaProductsApi";

import "./SouqAtlbhaProductsBox.css";
import PopupNotification from "../Popup/PopupNotification";
import ProductCard from "../Products/ProductCard";

const SouqAtlbhaProductsBox = () => {
	const [isOpenPopup, setIsOpenPopup] = useState(false);
	const [idProduct, setIdProduct] = useState(null);

	const [pageNumber, setPageNumber] = useState(8);
	const [currentPage, setCurrentPage] = useState(1);

	const { data: souqAtlbhaProducts, isLoading } = useGetAllProductsQuery({
		pageNumber,
		currentPage,
	});

	const SkeletonLoading = () => (
		<>
			{Array(8)
				.fill(null)
				.map((_, index) => (
					<div className='skeletonLoading' key={index}>
						<div className='order'>
							<div className='box-img'>
								<Skeleton
									animation='wavy'
									variant='rectangular'
									width='100%'
									height={230}
								/>
							</div>
							<div className='box-order'>
								<Skeleton variant='text' width={100} height={12} />
								<Skeleton variant='text' width={300} height={8} />
								<Skeleton variant='text' width={320} height={16} />
							</div>
						</div>
					</div>
				))}
		</>
	);

	if (isLoading) {
		return (
			<div className='skeletonLoading container'>
				<SkeletonLoading />
			</div>
		);
	}

	return (
		<div className='soq-product-page '>
			<div className='container'>
				{souqAtlbhaProducts?.products?.length ? (
					<section className='mb-5'>
						<div className='soq-product-body'>
							{souqAtlbhaProducts?.products?.map((product) => (
								<>
									<ProductCard
										product={product}
										setIsOpenPopup={setIsOpenPopup}
										isOpenPopup={isOpenPopup}
										setIdProduct={setIdProduct}
									/>
								</>
							))}
						</div>
						<div
							className='btn-container-row'
							style={{
								justifyContent:
									souqAtlbhaProducts.products.length <
									souqAtlbhaProducts.total_result
										? null
										: "center",
							}}>
							<div className='row-per-page-box'>
								<i>
									<IoIosArrowDown style={{ width: "22px" }} />
								</i>
								عدد الصفوف
								<Select
									value={pageNumber}
									onChange={(event) => {
										setPageNumber(event.target.value);
										setCurrentPage(1);
									}}>
									{[4, 8, 12, 16, 32].map((item) => (
										<MenuItem key={item} value={item}>
											{item}
										</MenuItem>
									))}
								</Select>
							</div>

							{souqAtlbhaProducts.products.length <
								souqAtlbhaProducts.total_result && (
								<>
									<Pagination
										count={souqAtlbhaProducts.page_count}
										page={currentPage}
										onChange={(event, value) => {
											setCurrentPage(value);
										}}
									/>
								</>
							)}
							<div></div>
						</div>
					</section>
				) : (
					<div
						className='d-flex flex-row align-items-center justify-content-center w-100'
						style={{ height: "50vh" }}>
						<p>لاتوجد منتجات في هذا القسم</p>
					</div>
				)}
			</div>

			<PopupNotification
				isOpenPopup={isOpenPopup}
				setIsOpenPopup={setIsOpenPopup}
				idProduct={idProduct}
			/>
		</div>
	);
};

export default SouqAtlbhaProductsBox;
