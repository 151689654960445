import React, { useContext, useEffect, useState } from "react";

// Third party
import { useNavigate } from "react-router-dom";

// Components
import { BlogHero, LoadingWebSite } from "../../index";

// RTK Query
import { useGetAllBlogsQuery } from "../../../RTK/Api/blogsApi";

// Blogs styles
import "./BlogBox.css";

// Context
import AppContext from "../../../Context/AppContext";
import { Skeleton } from "@mui/material";

const BlogBox = () => {
	const navigate = useNavigate();
	const [posts, setPosts] = useState([]);
	const [searchIsLoading, setSearchIsLoading] = useState(false);

	// posts context
	const postsContext = useContext(AppContext);
	const {
		postCategoryId,
		setPostCategoryId,
		postsNumber,
		setPostsNumber,
		setPostCategoryArray,
	} = postsContext;

	// get all blogs posts
	const { data: allBlogs, isLoading } = useGetAllBlogsQuery({
		postsNumber,
		postCategoryId: postCategoryId,
	});

	useEffect(() => {
		if (allBlogs?.pages?.length !== 0) {
			setPosts(allBlogs);
			setPostCategoryArray(allBlogs?.postCategory);
		}
	}, [allBlogs?.pages?.length]);

	const handleShowMoreBlogs = () => {
		setPostsNumber(postsNumber + 3);
	};

	const SkeletonLoading = () => (
		<>
			{Array(6)
				.fill(null)
				.map((_, index) => (
					<div className='box' key={index}>
						<div className='order'>
							<div className='box-img'>
								<Skeleton
									animation='wavy'
									variant='rectangular'
									width='100%'
									height={230}
								/>
							</div>
							<div className='box-order'>
								<Skeleton variant='text' width={100} height={12} />
								<Skeleton variant='text' width={300} height={8} />
								<Skeleton variant='text' width={400} height={16} />
							</div>
						</div>
					</div>
				))}
		</>
	);

	const goUpWindow = () => {
		window.scroll(0, 0);
	};

	return (
		<>
			<div className='blog-box'>
				<BlogHero
					setPosts={setPosts}
					allBlogs={allBlogs}
					setSearchIsLoading={setSearchIsLoading}
				/>
				{isLoading ? (
					<LoadingWebSite />
				) : (
					<div className='container'>
						{searchIsLoading ? (
							<div className='blog-box'>
								<div className='box-body'>
									<SkeletonLoading />
								</div>
							</div>
						) : (
							<>
								<div className='header-blog'>
									<ul className=' justify-content-center justify-content-lg-center'>
										<li
											className={
												postCategoryId === 0 ? "active bt-main" : "bt-main"
											}
											onClick={() => {
												setPostCategoryId(0);
											}}>
											الكل
										</li>
										{allBlogs?.postCategory.length !== 0
											? allBlogs?.postCategory?.map((el) => {
													return (
														<li
															className={
																postCategoryId === el?.id
																	? "active bt-main"
																	: "bt-main"
															}
															onClick={() => {
																setPostCategoryId(el?.id);
															}}
															key={el.id}>
															{el.name}
														</li>
													);
											  })
											: null}
									</ul>
								</div>

								{posts?.pages?.length ? (
									<div className='content-blog'>
										<div className='box-body'>
											{posts?.pages?.map((el) => {
												return (
													<div className='box' id={el?.id} key={el?.id}>
														<div
															className='order'
															onClick={() => {
																navigate(
																	`/post/${el?.id}/${encodeURIComponent(
																		el?.title
																			.replace(
																				/[^a-zA-Z0-9\u0621-\u064A]+/g,
																				"-"
																			)
																			.toLowerCase()
																	)}`
																);

																goUpWindow();
															}}>
															<div className='box-img'>
																<img
																	src={el.image}
																	alt={el.title}
																	loading='lazy'
																/>
															</div>
															<div className='box-order'>
																<h6>{el?.postCategory?.name}</h6>
																<h4>{el?.title}</h4>
																<p>{el?.page_desc}</p>
															</div>
														</div>
													</div>
												);
											})}
										</div>
										{posts?.pages?.length > allBlogs?.total_result && (
											<button
												className='bt-main'
												onClick={() => {
													handleShowMoreBlogs();
												}}>
												عرض المزيد
											</button>
										)}
									</div>
								) : (
									<div
										className='d-flex flex-row align-items-center justify-content-center w-100'
										style={{ height: "50vh" }}>
										<p>لاتوجد مقالات في هذا القسم</p>
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default BlogBox;
